var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { n as normalizeComponent } from "./index.js";
import "lodash";
import "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { staticClass: "dlg-create-container-type", attrs: { "ok-text": "Create", "title": "Add Container Type", "after-close": _vm.afterModalClose, "width": 520 }, on: { "ok": _vm.submit }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.cancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "loading": _vm.isLoading, "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.submit);
      } } }, [_vm._v("Add")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 24 } }, [_c("cf-select-input", { attrs: { "form-item": "", "rules": "required", "label": "Container Type", "placeholder": "Choose Container Type", "delete-filter": "IsInactive", "reference": "cf.common.yci-cont-types", "source": "containerType", "search-by": "ContainerType", "filter-type": "StartsWith", "api-not-get-paging": "", "all-in-value": "", "span": 24 }, on: { "change": _vm.onChangeContainerType } })], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("text-input", { attrs: { "form-item": "", "label": "Description", "rules": "required", "value": _vm.createForm.description, "disabled": true, "span": 24 } })], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("text-input", { attrs: { "form-item": "", "label": "Tare Weight", "rules": "required", "value": _vm.createForm.tareWeight, "disabled": true, "span": 24 } })], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("cf-select-input", { attrs: { "form-item": "", "label": "Numbering", "placeholder": "Select Numbering", "data-source": _vm.arrNumbering, "source": "source", "source-label": "sourceLabel", "rules": "required", "span": 24 }, on: { "change": function($event) {
      return _vm.storeValue("autoNumbering", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("number-input", { attrs: { "form-item": "", "rules": "required|min_value:1|max_value:4", "label": "Weighs per Container", "placeholder": "Type weighs per Container", "min": 1, "max": 4, "span": 24 }, on: { "change": function($event) {
      return _vm.storeValue("weighsPerContainer", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("number-input", { attrs: { "form-item": "", "rules": "min_value:-999.99|max_value:999.99", "label": "Hold Threshold", "placeholder": "0.00", "min": -999.99, "max": 999.99, "span": 24 }, on: { "change": function($event) {
      return _vm.storeValue("holdThreshold", $event);
    } } })], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateContainerType",
  inject: ["crud"],
  data() {
    return {
      createForm: {},
      arrNumbering: [
        {
          source: "A",
          sourceLabel: "Automatic"
        },
        {
          source: "E",
          sourceLabel: "Entered"
        }
      ],
      visible: false,
      isLoading: false
    };
  },
  mounted() {
    this.visible = true;
  },
  methods: {
    storeValue(resourceKey, value) {
      this.createForm = __spreadProps(__spreadValues({}, this.createForm), {
        [resourceKey]: value
      });
      this.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    onChangeContainerType(value) {
      if (!value) {
        return;
      }
      this.storeValue("code", value.containerType);
      this.storeValue("description", value.description);
      this.storeValue("tareWeight", value.tareWeight);
    },
    submit() {
      this.isLoading = true;
      this.crud.submitEntity("create").then(() => {
        this.crud.fetchList();
        this.visible = false;
        this.isLoading = false;
      }).catch(() => this.isLoading = false);
    },
    cancel() {
      this.visible = false;
    },
    afterModalClose() {
      this.$router.push("/container-filling/container-type");
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateContainerType = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-container-type" }, [_c("resource", { attrs: { "name": "cf.common.yci-cont-types", "api-url": _vm.apiUrl, "resource-id-name": "containerType" } }), _c("resource", { attrs: { "name": "cf.container-types", "api-url": _vm.apiUrl, "redirect-route": "/container-filling/container-type" } }, [_c("create-container-type")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateContainerType
  },
  data() {
    return {
      CreateContainerType,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
